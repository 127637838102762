import React from 'react';
import './PageNotFound.Style.css';

export const PageNotFound = () => {
  return (
    <div className="page-not-found-container">
      <span>Page Not Found</span>
    </div>
  );
};
